function showVideoWatchedStatus() {
    var videos = document.querySelectorAll('#videos .cell');

    videos.forEach(function(video) {
        var videoId = video.getAttribute('data-video-id');
        var videoWatched = localStorage.getItem('video-watched-' + videoId);

        if (videoWatched) {
            video.classList.add('video-watched');
            video.appendChild(document.createTextNode(' - Watched on ' + new Date(videoWatched).toLocaleString()));
        }
    });
}

window.addEventListener('load', function() {
    showVideoWatchedStatus();
});